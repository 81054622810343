//- Fonts
@import "general/fonts"

@import "abstracts/variables"
// @import "abstracts/mixins"
//- Icons
@import "general/icons"

//- Init
// @import "general/init"

//- Cards
// @import "general/cards"
.catDivCPlan	
	position: relative
	padding-bottom: $spacer3/2
	&:after 
		content: ''
		width: 100% 
		height: 1px 
		background-color: $gris2
		position: absolute
		bottom: -1px
		left: 0	
//- Pagination - Mixin 
@import "general/pagination"
//- StepsPoint - Mixin 
@import "general/stepsPoint"
//Slider Tabs
.sliderTabs
	width: 100%
	> h2, > h3
		margin-bottom: 2px
		+mediaMin640
			margin-bottom: $spacer * 2
	.sliderTabsMenu
		+bx
		+flex
		+justifyCont(center)
		width: calc(100% + #{$spacer2 * 2})
		margin-left: -$spacer2
		margin-right: -$spacer2
		+mediaMin640
			width: 100%
			max-width: 960px
			margin: 0
			margin-left: auto
			margin-right: auto
		&.onlyOne
			.stmElement
				width: 100%
		&.pills
			background-color: #fff
			+mediaMin640
				+borderRadius($spacer2)
				+justifyCont(flex-start)
				padding: $spacer2
			.stmElement
				+font-size(14px)
				height: 50px
				+mediaMin640
					+borderRadius($spacer)
					width: auto
					height: auto
					padding: $spacer $spacer3
				+mediaMin760
					+font-size(18px)
				&:after
					display: none
				&.active
					color: $rojo6
					+mediaMin640
						background-color: $gris3
						color: $color-text
			.slick-prev
				&:after
					height: 100%
					background: rgb(255,255,255) !important
					background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%) !important
					background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%) !important
					background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%) !important
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1) !important
			.slick-next
				&:after
					height: 100%
					background: rgb(255,255,255) !important
					background: -moz-linear-gradient(270deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%) !important
					background: -webkit-linear-gradient(270deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%) !important
					background: linear-gradient(270deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%) !important
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1) !important
		.stmElement
			+bx
			+font-size(16px)
			+flex(!important)
			+alignItem(center)
			+justifyCont(center)
			font-weight: 700
			text-align: center
			position: relative
			width: 50%
			height: 60px
			padding: 0 $spacer
			cursor: pointer
			color: $gris5
			+mediaMin640
				+font-size(18px)
				//height: 80px
				height: auto 
				padding-top: $spacer - 2
				padding-bottom: $spacer2
			p
				span
					display: inline-block
					padding-right: $spacer0
					i
						margin-right: $spacer/2
						display: none
						+mediaMin640
							display: inline-block
					&:last-child
						padding-right: 0
			&:after
				+fx(all, 0.5s)
				content: ''
				position: absolute
				left: 0
				bottom: 0
				width: 100%
				height: 4px
				display: block
				background-color: $gris3
			&.active
				color: $color-text
				&:after
					height: 8px
					background-color: $rojo6
			+mediaMin640
				width: 25%
		.slick-slider
			position: relative
			width: 100%
		.slick-slide
			.stmElement
				pointer-events: none
		.slick-prev, .slick-next
			+bx
			+fx(all, 0.5s)
			width: 48px
			height: 52px
			z-index: 1
			&:before
				+center(vh)
				+fx(all, 0.5s)
				+font-size(18px)
				color: $color-text
		.slick-prev
			left: 0
			&:after
				content: ''
				position: absolute
				left: 0
				top: 0
				width: 100%
				height: calc(100% - 4px)
				display: block
				background: rgb(255,255,255)
				background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)
				background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)
				background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1)
			&:before
				+claroIcon('e92f')
				left: 30%
				z-index: 1
		.slick-next
			right: 0
			&:after
				content: ''
				position: absolute
				left: 0
				top: 0
				width: 100%
				height: calc(100% - 4px)
				display: block
				background: rgb(255,255,255)
				background: -moz-linear-gradient(270deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)
				background: -webkit-linear-gradient(270deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)
				background: linear-gradient(270deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1)
			&:before
				+claroIcon('e930')
				left: 70%
				z-index: 1
	.sliderTabsViewplanesDestacados
		display: block !important
		// -webkit-animation: fade-in 0.5s ease
		// -moz-animation: fade-in 0.5s ease
//- Slider
@import "general/slider"

//Modal Generic
.modalClaro
	+bx
	+borderRadius(10px)
	padding: $spacer3 !important
	padding-bottom: 0px !important
	background: #fff
	max-width: 100%
//Modal Generic Middle
.modalClaroMid
	+borderRadius($spacer)
	width: 600px
	padding: $spacer4 $spacer3 0 !important
//- Modal
@import "general/modal/all"

// Atoms
@import 'Components/Atoms/all'
// Molecules
@import 'Components/Molecules/all'